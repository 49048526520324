.app {
  text-align: center;
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: center;
  background-color: #333;
  padding: 1rem;
}

.navbar a {
  color: white;
  margin: 0 1rem;
  text-decoration: none;
}

.navbar a:hover {
  text-decoration: underline;
}