.hero {
    background-color: #f0f0f0;
    padding: 2rem;
  }
  
  .videos-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
  }
  
  .video-item {
    background-color: #e8e8e8;
    padding: 1rem;
    border-radius: 8px;
  }